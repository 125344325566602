import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import { Loading } from "../components/common/loading";
import { isUserLogged } from "../utils/localStorage";
import { LINKS } from "../constants/links";
import { clearLayoutAction, setLayoutAction } from "../state/layout.reducer";

const AuthLoadingPage = () => {
	const dispatch = useDispatch();
	const [freeze, setFreeze] = useState(true);

	useEffect(() => {
		setTimeout(() => setFreeze(false), 1500);
		dispatch(setLayoutAction({ withoutHeader: true }));

		return () => {
			dispatch(clearLayoutAction());
		};
	}, []);

	useEffect(() => {
		if (!freeze) {
			navigate(isUserLogged() ? LINKS.HOMEPAGE : LINKS.LOGIN);
		}
	}, [freeze]);

	return <Loading />;
};

export default AuthLoadingPage;
